<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'products'}">Produtos</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações do Produto</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Id</label>
							<input
								v-model.trim="product.id"
								class="form-control"
								disabled="disabled"
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Código<ej-asterisk v-if="product.type === 'cabin'"/></label>
							<input
								v-model.trim="product.code"
								class="form-control"
							/>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label>Nome*</label>
							<input
								v-model.trim="product.name"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Marca*</label>
							<input
								v-model.trim="product.brand"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Tipo*</label>
							<ej-select
								v-model.trim="type"
								:options="types"
								track-by="value"
								label="name"
							></ej-select>
						</div>

                        <div class="form-group col-lg-2 col-12" v-if="product.type === 'cabin'">
                            <label>Linha<ej-asterisk/></label>
                            <ej-select
                                v-model.trim="line"
                                :options="lines"
                            ></ej-select>
                        </div>

						<div class="form-group col-lg-2 col-12">
							<label>MDA</label>
							<input
								v-model.trim="productMetaModel['mda']"
								class="form-control"
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>NCM</label>
							<input
								v-model.trim="productMetaModel['ncm']"
								class="form-control"
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Estoque <font-awesome-icon v-tooltip="'Somente visualização. Este valor é consultado diretamente no Holma.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<input
								:value="product.stock"
								class="form-control"
								disabled
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Custo <font-awesome-icon v-tooltip="'Somente visualização. Este valor é consultado diretamente no Holma.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<currency-input
								:value="product.cost"
								class="form-control"
								disabled
							/>
						</div>

						<div class="col-lg-6 col-12 d-flex flex-wrap">
                            <div class="form-group mr-3">
                                <label>Ativo?*</label><br>
                                <ej-toggle-button
                                    v-model.trim="product.active"
                                    :labels="{checked: 'Sim', unchecked: 'Não'}"
                                />
                            </div>

							<div class="form-group mr-3">
								<label>Comercializável?</label><br>
								<ej-toggle-button
									v-model.trim="product.salable"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
								/>
							</div>

							<div class="form-group mr-3">
								<label>Informar preço?*</label><br>
								<ej-toggle-button
									v-model.trim="priceEnabled"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
								/>
							</div>

							<div v-if="priceEnabled" class="form-group mr-3">
								<label>Preço*</label>
								<currency-input
									v-model.trim="product.price"
									class="form-control"
								/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">

						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">

						</div>
					</div>

					<div class="row">
						<div v-if="product.type === 'cabin'" class="col-lg-4 mt-3">
							<div>
								<h5>Itens de série</h5>
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th>Item</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Ar condicionado
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.ar_condicionado']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Espelhos retrovisores
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.espelhos_retrovisores']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Tapetes protetores
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.tapetes_protetores']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Iluminação interna
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.iluminacao_interna']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Iluminação externa (6 faróis em LED)
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.iluminacao_externa']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Vedações
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.vedacoes']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Limpador dianteiro
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.limpador_dianteiro']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Maçaneta com chave
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.macaneta_chave']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
										<tr>
											<td>
												Vidros temperados
											</td>
											<td>
												<ej-toggle-button
													v-model.trim="productMetaModel['itens_serie.vidros_temperados']"
													:labels="{checked: 'Sim', unchecked: 'Não'}"
												/>
											</td>
										</tr>
									</tbody>
								</table>

								<div class="mobile-list-content">
									<div class="row">
										<div class="col-12 col-md-3" :key="index" v-for="(item, index) in compatibilityBrute">
											<div class="card">
												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Ar condicionado
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.ar_condicionado']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Espelhos retrovisores
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.espelhos_retrovisores']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Tapetes protetores
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.tapetes_protetores']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Iluminação interna
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.iluminacao_interna']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Iluminação externa (6 faróis em LED)
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.iluminacao_externa']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
                                                        Vedações
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.vedacoes']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Limpador dianteiro
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.limpador_dianteiro']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Maçaneta com chave
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.macaneta_chave']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="card-body d-flex align-items-center justify-content-between">
													<h5 class="card-title mb-0 mr-2">
														Vidros temperados
													</h5>

													<div class="actions m-0">
														<ej-toggle-button
															v-model.trim="productMetaModel['itens_serie.vidros_temperados']"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 mt-3">
							<div>
								<h5>Categorias*</h5>
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th>Categoria</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(item, index) in categoriesBrute">
											<td>
												<ej-select
													v-model.trim="categoriesBrute[index]"
													:options="categories"
													track-by="id"
													label="name"
													placeholder="Buscar"
												></ej-select>
											</td>
											<td>
												<ej-button class="btn btn-primary" @click="removeCategory(index)">
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</ej-button>
											</td>
										</tr>
									</tbody>
								</table>

								<div class="mobile-list-content">
									<div class="row">
										<div class="col-12 col-md-3" :key="index" v-for="(item, index) in categoriesBrute">
											<div class="card">
												<div class="card-body d-flex align-items-center">
													<h5 class="card-title mb-0 mr-2">
														<ej-select
															v-model.trim="categoriesBrute"
															:options="categories"
															track-by="id"
															label="name"
															placeholder="Buscar"
														></ej-select>
													</h5>

													<div class="actions m-0">
														<button class="btn btn-primary m-0" @click="removeCategory(index)">
															<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="text-center">
								<ej-button class="btn btn-secondary hvr-pulse" @click="addCategory">
									<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Categoria
								</ej-button>
							</div>
						</div>

						<div class="col-lg-4 mt-3">
							<div>
								<h5>Compatível com (aplicável em)</h5>
								<table class="desktop-list-content">
									<thead>
										<tr>
										<th>Produto</th>
										<th></th>
									</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(item, index) in compatibilityBrute">
										<td>
											<ej-select
												v-model.trim="compatibilityBrute[index]"
												:options="products"
												track-by="id"
												label="name"
												placeholder="Buscar"
											></ej-select>
										</td>
										<td>
											<ej-button class="btn btn-primary" @click="removeItem(index)">
												<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
											</ej-button>
										</td>
									</tr>
									</tbody>
								</table>

								<div class="mobile-list-content">
									<div class="row">
										<div class="col-12 col-md-3" :key="index" v-for="(item, index) in compatibilityBrute">
											<div class="card">
												<div class="card-body d-flex align-items-center">
													<h5 class="card-title mb-0 mr-2">
														<ej-select
															v-model.trim="compatibilityBrute[index]"
															:options="products"
															track-by="id"
															label="name"
															placeholder="Buscar"
														></ej-select>
													</h5>

													<div class="actions m-0">
														<button class="btn btn-primary m-0" @click="removeItem(index)">
															<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="text-center">
								<ej-button class="btn btn-secondary hvr-pulse" @click="addItem">
									<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Produto
								</ej-button>
							</div>
						</div>
					</div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Mixins
	import FetchingMixin from "@/mixins/FetchingMixin";
	import Swal from "sweetalert2";
	import {exibeErro, sanitize} from "@/helpers";

	//Aux
	import EjButton from "@/components/EjButton";
	import {vueSet} from "vue-deepset";
    import EjAsterisk from "@/components/EjAsterisk.vue";
	const _ = require('lodash');

	export default {
		name: 'ProductEdit',
		components: {EjAsterisk, EjButton},

		data() {
			return {
				types: [
					{name: 'Cabina', value: 'cabin'},
					{name: 'Equipamento Agrícola', value: 'equipment'},
					{name: 'Outro', value: 'other'},
				],
                lines: [
                    'COLHEITADEIRAS',
                    'FROOTER',
                    'SIGNIA/REVUS',
                ],
				loadingProduct: false,
				loadingProducts: false,
				loadingCategories: false,
				saving: false,
				operation: undefined,
				priceEnabled: false,
				products: [],
				compatibilityBrute: [],
				categoriesBrute: [],

				product: {
					name: undefined,
					brand: undefined,
					active: true,
					type: undefined,
					line: undefined,
					salable: true,
					compatibility: [],
					categories: []
				},

				/* Criei o meta fora do product por causa do problema de reatividade do Vue,
				no final eu incluo ele pra salvar */
				productMeta: {},
			}
		},

		watch: {
			"product.type"() {
				if (!this.loadingProduct)
					this.productMeta['itens_serie'] = {
                        ar_condicionado: true,
                        espelhos_retrovisores: true,
                        tapetes_protetores: true,
                        iluminacao_interna: true,
                        iluminacao_externa: true,
                        vedacoes: true,
                        limpador_dianteiro: true,
                        macaneta_chave: true,
                        vidros_temperados: true,
                    };
			},

			priceEnabled(newValue) {
				if (!newValue)
					this.product.price = null;
			},

			compatibility(newValue) {
				newValue.forEach(function(p, index) {
					//Se o array de opções equivalente, ainda não existe
					if (!_.get(this.compatibilityOptions,index))
						this.compatibilityOptions[index] = [{
							name: p.name,
							value: p.id
						}];
				});
			}
		},

		computed: {
			productMetaModel() {
				return this.$deepModel(this.productMeta);
			},

			type: {
				get() {
					const type = _.get(this.product, 'type');
					return type ? this.types.find((t) => t.value == type) : null;
				},

				set(type) {
					this.product.type = (type) ? type.value : null;
				}
			},

            line: {
                get() {
                    return _.get(this.product, 'line');
                },

                set(line) {
                    this.product.line = line ?? null;
                }
            },

			spinner() {
				return this.loadingProduct || this.loadingProducts || this.loadingCategories;
			}
		},

		mixins: [
			FetchingMixin
		],

		methods: {
			salvar() {
				const vm = this;

				this.product.compatibility = this.compatibilityBrute.map(function(item) {
					return item.id;
				});

				this.product.categories = this.categoriesBrute.map(function(item) {
					return item.id;
				});

				vueSet(this.product, 'meta', this.productMeta);

				this.saving = true;
				switch (this.operation) {
					case 'create':
						this.$store.dispatch(`products/post`, sanitize(this.product))
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Produto criado com sucesso!',
									'success'
								).then(function(result) {
									if (result.isConfirmed)
										window.location = '/products';
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;

					case 'edit':
						this.$store.dispatch(`products/patch`, {
							id: this.product.id,
							data: sanitize(this.product)
						})
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Produto atualizado com sucesso!',
									'success'
								);
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;
				}
			},

			addItem() {
				this.compatibilityBrute.push({});
			},

			addCategory() {
				this.categoriesBrute.push({});
			},

			removeItem(index) {
				this.$delete(this.compatibilityBrute, index);
			},

			removeCategory(index) {
				this.$delete(this.categoriesBrute, index);
			},
		},

		mounted() {
			this.operation = this.$route.name === 'products.edit' ? 'edit' : 'create';

			const vm = this;

			if (this.operation === 'edit') {
				this.loadingProduct = true;
				this.$store.dispatch(`products/get`, vm.$route.params.id)
					.then(function (response) {
						vm.compatibilityBrute = response.data.compatibility ?? [];
						vm.categoriesBrute = response.data.categories ?? [];
						vm.product = Object.assign(vm.product, response.data);
						vueSet(vm, 'productMeta', _.get(response.data,'meta') ? response.data.meta : {});

						if (_.get(vm.product,'price'))
							vm.priceEnabled = true;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingProduct = false;
					});
			}

			this.loadingProducts = true;
			this.$store.dispatch(`products/index`, {sort:'products.name', per_page: 9999})
				.then(function (response) {
					vm.products = response.data.data;
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingProducts = false;
				});

			this.loadingCategories = true;
			this.$store.dispatch(`categories/index`, {sort:'categories.name', per_page: 9999})
				.then(function (response) {
					vm.categories = response.data.data;
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingCategories = false;
				});
		}
	}
</script>

<style scoped>
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}
	}

	.card label {
		margin-bottom: 0;
	}
</style>
